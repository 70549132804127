import React from "react";
import _data from "../../data";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            privacy: {}
        };
    }

    componentDidMount() {
        this.setState({
            privacy: _data.privacy
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="promo-section ptb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <a className="btn solid-btn mb-5" href="/">
                                    {this.state.privacy.button}
                                </a>
                                <div className="section-heading mb-5">
                                    <h2>{this.state.privacy.title}</h2>
                                    <p className="lead">
                                        Cześć! Super, że tutaj zajrzałeś, bo oznacza to, że dbasz o swoją prywatność i
                                        chcesz wiedzieć, kto oraz w jaki sposób przetwarza Twoje dane. Znajdziesz tutaj
                                        zasady przetwarzania danych osobowych oraz wykorzystania ciasteczek (plików
                                        cookies), podczas korzystania ze strony
                                        <a href="https://devlog.it"> devlog.it</a>.
                                    </p>
                                    <p className="lead">
                                        Administratorem strony jest firma FoolProofSoft Mateusz Michalski, ul.
                                        Pułkownika Hynka 16/10, 62-230 Witkowo, NIP: 7842507708, REGON: 368082613.
                                    </p>
                                    <p className="lead">
                                        W razie wątpliwości związanych z polityką prywatności, w każdej chwili możesz
                                        napisać do mnie wiadomość na adres: news@devlog.it.
                                    </p>
                                    <h3>Prawa do swoich danych</h3>
                                    <p className="lead">
                                        Zgodnie z rozporządzeniami RODO przyznaje Ci następujące potencjalne uprawnienia
                                        związane z przetwarzaniem Twoich danych osobowych
                                    </p>
                                    <ul className="newsletter-pointers">
                                        <li>
                                            dostępu do danych osobowych,
                                        </li>
                                        <li>
                                            sprostowania danych osobowych,
                                        </li>
                                        <li>
                                            usunięcia danych osobowych,
                                        </li>
                                        <li>
                                            ograniczenia przetwarzania danych osobowych,
                                        </li>
                                        <li>
                                            wniesienia sprzeciwu co do przetwarzania danych osobowych,
                                        </li>
                                        <li>
                                            przenoszenia danych,
                                        </li>
                                        <li>
                                            wniesienia skargi do organu nadzorczego,
                                        </li>
                                        <li>
                                            odwołania zgody na przetwarzanie danych osobowych, jeżeli taką wyraziłeś.
                                        </li>
                                    </ul>
                                    <p className="lead pt-2">
                                        Nie dotyczy to żadnych danych które jestem zobligowany zachować ze względów
                                        administracyjnych, prawnych albo bezpieczeństwa.
                                    </p>
                                    <h3>Kto ma dostęp do danych</h3>
                                    <p className="lead">
                                        Dane osobowe powierzane są do przetwarzania następującym podmiotom:
                                    </p>
                                    <ul className="newsletter-pointers">
                                        <li>FoolProofSoft Mateusz Michalski, ul. Pułkownika Hynka 16/10, 62-230
                                            Witkowo,
                                        </li>
                                        <li>OVH Sp. z o.o., ul. Swobodna 1, 50-088 Wrocław, NIP: 899-25-20-556,
                                            REGON: 933029040,
                                        </li>
                                        <li>The Rocket Science Group, LLC 675 Ponce de Leon Ave NE Suite 5000.
                                            Atlanta, GA 30308 USA,
                                        </li>
                                        <li>Google Inc. 1600 Amphitheatre Parkway, Mountain View, USA</li>
                                    </ul>
                                    <h3 className="pt-2">Newsletter</h3>
                                    <p className="lead">
                                        Dołączenie do newslettera wymaga przekazania mi Twojego adresu e-mail poprzez
                                        formularz zapisu lub poprzez wyrażenie odrębnej zgody w procesie zakładania
                                        konta. Dane, które mi przezkażesz wykorzystywane będą w celu przesyłania Ci
                                        newslettera. Podstawą prawną do tego jest Twoja zgoda
                                        (art. 6 ust. 1 lit. a RODO) wyrażona podczas procesu zapisywania.
                                    </p>
                                    <p className="lead">
                                        Dane przetwarzane są przez czas funkcjonowania newslettera, chyba że wcześniej
                                        zrezygnujesz z jego otrzymywania, co spowoduje usunięcie Twoich danych z bazy.
                                        Dane przechowywane są na serwerze firmy The Rocket Science Group, LLC i
                                        przetwarzane przez system Mailchimp. W każdy momencie możesz poprawić swoje dane
                                        zapisane w bazie newsletterowej oraz zażądać ich usunięcia, poprzez rezygnację
                                        z otrzymywania newslettera. Przysługuje Ci też prawo do przenoszenia danych, o
                                        którym mowa w art. 20 RODO.
                                    </p>
                                    <h3>Kontakt e-mail</h3>
                                    <p className="lead">
                                        Poprzez kontakt za pośrednictwem poczty elektronicznej,
                                        przekazujesz mi adres e-mail jako adres nadawcy wiadomości. Ponadto, w treści
                                        wiadomości e-mail możesz zawrzeć również inne dane osobowe. Twoje dane są w tym
                                        przypadku przetwarzane w celu kontaktu z Tobą, a podstawą przetwarzania jest
                                        art. 6 ust. 1 lit. f RODO, czyli prawnie uzasadniony interes administratora
                                        polegający na udzieleniu odpowiedzi na Twoją wiadomość. Podstawą prawną
                                        przetwarzania po zakończeniu kontaktu jest prawnie uzasadniony interes
                                        administratora polegający na archiwizacji korespondencji na potrzeby wewnętrzne
                                        (art. 6 ust. 1 lit. f RODO).
                                    </p>
                                    <p className="lead">
                                        Treść korespondencji może podlegać archiwizacji. Masz prawo do domagania się
                                        przedstawienia historii korespondencji, jaką ze mną prowadziłeś (jeżeli
                                        podlegała archiwizacji), jak również domagać się jej usunięcia, chyba że jej
                                        archiwizacja jest uzasadniona z uwagi na moje nadrzędne interesy, np. obrona
                                        przed potencjalnymi roszczeniami.
                                    </p>
                                    <h3>Pliki cookies i inne technologie śledzące</h3>
                                    <p className="lead">
                                        Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu
                                        końcowym (np. komputerze, tablecie, smartfonie), które mogą być odczytywane
                                        przez system teleinformatyczny.
                                    </p>
                                    <h4>Narzędzia społecznościowe</h4>
                                    <p className="lead">
                                        Na stronie pojawiają się nagrania ze strony YouTube. Podczas ich odtworzania,
                                        wykorzystywane są pliki cookies firmy Google LLC.
                                    </p>
                                    <h4>Osadzone treści z innych witryn</h4>
                                    <p className="lead">
                                        Na tej witrynie mogą być osadzone treści (np. filmy, obrazki, artykuły
                                        itp.). Osadzone treści z innych witryn zachowują się analogicznie do tego, jakby
                                        Użytkownik odwiedził bezpośrednio konkretną witrynę. Witryny mogą zbierać
                                        informacje o Użytkowniku, używać ciasteczek, dołączać dodatkowe, zewnętrzne
                                        systemy śledzenia i monitorować interakcje użytkownika z osadzonym materiałem,
                                        włączając w to śledzenie interakcji z osadzonym materiałem jeśli Użytkownik
                                        posiada konto i jest zalogowany w tamtej witrynie.
                                    </p>
                                    <h3>Logi serwera</h3>
                                    <p className="lead">
                                        Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera, na którym
                                        hostowana jest strona. Każde zapytanie skierowane do serwera zapisywane jest
                                        w logach i obejmuje m.in. Twój adres IP, datę i czas serwera, informacje o
                                        przeglądarce internetowej i systemie operacyjnym z jakiego korzystasz. Dane
                                        te nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są
                                        wykorzystywane w celu identyfikacji.
                                    </p>
                                    <p className="lead">
                                        Logi serwera pełnią rolę pomocniczą, służącą do administrowania stroną, a dostęp
                                        do nich mają tylko osoby upoważnione do administrowania serwerem.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default NotFound;
