import React from "react";
import _data from "../../data";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            footer: {}
        }
    };

    componentDidMount() {
        this.setState({
            footer: _data.footer
        });
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section">
                    <div className="footer-bottom gray-light-bg pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-5">
                                    <div className="social-list-wrap">
                                        <ul className="social-list list-inline list-unstyled">
                                            {(this.state.footer.socials || []).map(social => {
                                                return (
                                                    <li key={social.title} className="list-inline-item">
                                                        <a href={social.link} target={"_blank"} rel="noreferrer" title={social.title}>
                                                            <span className={social.icon}></span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <p className="copyright-text pb-0 mb-0">
                                        {this.state.footer.copyrights} <a href="/privacy">{this.state.footer.title}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
