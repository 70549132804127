import React from "react";
import _data from "../../data";

export default class Premium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      premium: {}
    };
  }

  componentDidMount() {
    this.setState({
      premium: _data.premium
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section pt-50">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/baza-wiedzy.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                <div className="feature-contents section-heading">
                  <h2 className={"text-center"}>
                    {this.state.premium.title}
                  </h2>
                  <p className="lead text-center">
                    {this.state.premium.description}
                  </p>
                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab justify-content-center" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                            className="nav-link active h6"
                            href="#tab6-1"
                            data-toggle="tab"
                        >
                          <span className="ti-search"></span>
                          Sprawdź
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                            className="nav-link h6"
                            href="#tab6-2"
                            data-toggle="tab"
                        >
                          <span className="ti-archive"></span>
                          Baza wiedzy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-email"></span>
                          2x więcej
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-4"
                          data-toggle="tab"
                        >
                          <span className="ti-key"></span>
                          Rozwiązania
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-5"
                          data-toggle="tab"
                        >
                          <span className="ti-star"></span>
                          Dodatkowo
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane active" id="tab6-1">
                        <p className="mb-3 lead text-center">
                          Ja też nie lubię kupować kota w worku. Dlatego umieszczam poniżej linki do pierwszych trzech wydań. Sprawdź i przekonaj się, że warto!
                        </p>
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <h5 className="mb-0">
                                  <a href="https://mailchi.mp/091a3673e00b/devlog-wydanie-1" target="_blank" rel="noreferrer">
                                    I wydanie: Jak zacząć programować?
                                  </a>
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <h5 className="mb-0">
                                  <a href="https://mailchi.mp/285e958c0310/devlog-wydanie-2" target="_blank" rel="noreferrer">
                                    II wydanie: Jaki język programowania wybrać?
                                  </a>
                                </h5>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <h5 className="mb-0">
                                  <a href="https://mailchi.mp/5fdd473b51ab/devlog-wydanie-3" target="_blank" rel="noreferrer">
                                    III wydanie: Aplikacja TODO na sterydach
                                  </a>
                                </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-pane" id="tab6-2">
                        <div className="single-feature mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-write rounded mr-3 icon icon-color-1"></span>
                            <h5 className="mb-0">Informacje na wyciągnięcie ręki</h5>
                          </div>
                          <p className="mb-0">
                            Wiele przydatnych linków, pomysłów na aplikacje, zadań rekrutacyjnych, zespołów projektowych oraz wskazówek związanych z nauką i pracą w IT w jednym miejscu!
                          </p>
                        </div>
                        <div className="single-feature mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-stats-up rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Stale rozwijana i powiększana</h5>
                          </div>
                          <p className="mb-0">
                            Baza cały czas rośnie! Systematycznie dorzucam kolejne pomysły, zadania, opracowania i success stories do Motywatorni 💪
                            Ta baza jest wręcz bezcenna!
                          </p>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-3">
                          <div className="single-feature mb-2">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-unlock rounded mr-3 icon icon-color-4"></span>
                              <h5 className="mb-0">2x więcej treści i wydań (w KAŻDY piątek)</h5>
                            </div>
                            <p className="mb-1">
                              Czytaj wydania wypełnione po brzegi wiedzą w każdy piątek o 12:00!
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="single-feature mb-2">
                              <div className="d-flex align-items-center mb-2">
                                <span className="ti-write rounded mr-3 icon icon-color-5"></span>
                                <h5 className="mb-0">2x więcej zadań rekrutacyjnych</h5>
                              </div>
                              <p className="mb-1">
                                Zbieraj zadania, które przydadzą Ci się w przygotowaniach do rozmowy o pracę.
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="single-feature mb-2">
                              <div className="d-flex align-items-center mb-2">
                                <span className="ti-briefcase rounded mr-3 icon icon-color-2"></span>
                                <h5 className="mb-0">2x więcej ofert pracy dla Juniorów</h5>
                              </div>
                              <p className="mb-1">
                                Przeglądaj więcej ciekawych (wybranych przeze mnie) ofert na staż/pracę!
                              </p>
                            </div>
                          </div>
                      </div>
                      <div className="tab-pane" id="tab6-4">
                        <div className="single-feature mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-light-bulb rounded mr-3 icon icon-color-3"></span>
                            <h5 className="mb-0">Sprawdź się!</h5>
                          </div>
                          <p>
                            Każde wydanie posiada jedno zadanie rekrutacyjne o różnym poziomie trudności. Zadania teoretyczne, które są przeplatane zadaniami praktycznymi.
                            Wersja PREMIUM oprócz zadań posiada odpowiedzi, dzięki którym od razu możesz zweryfikować swoją wiedzę.
                          </p>
                          <p>
                            Wszystkie zadania rekrutacyjne wraz z odpowiedziami umieszczam w bazie wiedzy!
                          </p>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab6-5">
                          <div className="single-feature mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-archive rounded mr-3 icon icon-color-4"></span>
                            <h5 className="mb-0">Dostęp do wszystkich wydań</h5>
                          </div>
                          <p className="mb-0">
                            Archiwum ze wszystkimi wydaniami umieszczone w bazie wiedzy.
                          </p>
                        </div>
                        <div className="single-feature mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-headphone rounded mr-3 icon icon-color-5"></span>
                            <h5 className="mb-0">Wydania w wersji audio</h5>
                          </div>
                          <p className="mb-0">
                            Nie zawsze jest czas na czytanie, dlatego wydania są też w wersji audio.
                          </p>
                        </div>
                        <div className="single-feature mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-gift rounded mr-3 icon icon-color-6"></span>
                            <h5 className="mb-0">Kody rabatowe i zniżki tylko dla DevLog</h5>
                          </div>
                          <p className="mb-0">
                            Przynajmniej raz w miesiącu staram się zorganizować kody rabatowe na kursy, szkolenia, itp. tylko dla DevLog!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
