import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Page from "../themes";
import NotFound from "../themes/404";
import PrivacyTheme from "../themes/Privacy";

export default class Routes extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Page}/>
                        <Route exact path="/privacy" component={PrivacyTheme}/>
                        <Route path='*' exact={true} component={NotFound} />
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}
