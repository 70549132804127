import React, {Component} from "react";

import NotFound from "../components/NotFound";

class NotFoundTheme extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <NotFound/>
                </div>
            </React.Fragment>
        );
    }
}

export default NotFoundTheme;
