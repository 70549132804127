import React from "react";
import _data from "../../data";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}
    };
  }

  componentDidMount() {
    this.setState({
      aboutUs: _data.aboutUs
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className="feature-section">
          <div className="container">
            <div className="row row-grid align-items-center">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                  {(this.state.aboutUs.itemsFirstPackage || []).map((item, index) => {
                    return (
                        <div className="d-flex align-items-start mb-5" key={index}>
                          <div className="pr-4">
                            <div className={"icon icon-shape rounded-circle " + item.iconColor}>
                              <span className={item.icon}></span>
                            </div>
                          </div>
                          <div className="icon-text">
                            <h5>{item.title}</h5>
                            <p className="mb-0">
                              {item.description}
                            </p>
                          </div>
                        </div>
                    );
                  })}
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                <div className="position-relative" style={{zIndex: 10}}>
                  <img
                    alt="placeholder"
                    src="img/app-product_mail_2.png"
                    className="img-center img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                {(this.state.aboutUs.itemsSecondPackage || []).map((item, index) => {
                  return (
                      <div className="d-flex align-items-start mb-5" key={index}>
                        <div className="pr-4">
                          <div className={"icon icon-shape rounded-circle " + item.iconColor}>
                            <span className={item.icon}></span>
                          </div>
                        </div>
                        <div className="icon-text">
                          <h5>{item.title}</h5>
                          <p className="mb-0">
                            {item.description}
                          </p>
                        </div>
                      </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
