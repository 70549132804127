module.exports = {
    hero: {
        title: "Wskazówki i wiedza dla początkujących programistów",
        description: "Dołącz do setek osób czytających mailing z praktyczną wiedzą, zadaniami rekrutacyjnymi, ofertami pracy i wskazówkami skierowanymi do początkujących programistów! ",
        bgImage: "img/background-main.jpg"
    },
    video: {
        link: "https://youtu.be/5QXRe72q8zc"
    },
    mailchimp: {
        button: "ZACZYNAMY",
        buttonSend: "Wysyłam...",
        buttonSuccess: "Wysłano!",
        titleModalError: "Ups... 😕",
        titleModalSuccess: "Brawo! Dobra decyzja 👏",
        descriptionModalSuccess: "Nie traćmy czasu, wysyłam do Ciebie maila powitalnego i linki do pierwszych wydań!",
        descriptionModalError: "Coś poszło nie tak... Jeżeli problem nie zniknie, daj mi proszę znać na: news@devlog.it",
        buttonModalSuccess: "Widzimy się za chwilę 😉",
        buttonModalError: "Spróbuj za chwilę 🙏",
        input: "E-mail",
        rules: "Uzupełnienie powyższego pola stanowi zgodę na otrzymywanie od FoolProofSoft Mateusz Michalski newslettera zawierającego treści informacyjne oraz marketingowe dotyczące portalu devlog.it. Zgodę można wycofać w każdym czasie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem."
    },
    promo: {
        title: "Dlaczego warto się zapisać?",
        description: "W sieci jest wiele materiałów, rozsianych po różnych blogach, forach, czy tutorialach. Opracowuję najciekawsze i najważniejsze tematy związane z programowaniem oraz karierą i wysyłam je do Ciebie! ",
        boldDescription: "Co zyskasz zapisując się na mailing?",
        items: [
            {
                title: "Regularność",
                description: "Co tydzień, o stałej godzinie nowa porcja wartościowej wiedzy i ciekawostek z branży!",
                icon: "ti-calendar text-white"
            },
            {
                title: "Rozwój i nauka",
                description: "Starannie wyselekcjonowane i opracowane materiały, oparte na wieloletnim doświadczeniu.",
                icon: "ti-star text-white"
            },
            {
                title: "Oszczędność czasu",
                description: "Nie tracisz czasu na research! Informacje same do Ciebie trafiają, prosto na Twoją pocztę.",
                icon: "ti-alarm-clock text-white"
            }
        ]
    },
    counter: {
        title: "Nie przegap kolejnej dawki MEGA wiedzy!",
        description: "Nauka, wskazówki, zadania rekrutacyjne, oferty pracy dla Juniorów ",
        descriptionUnderline: "w piątki o 12:00",
        descriptionIcon: "😉"
    },
    aboutUs: {
        title: "Wiedza dla początkujących oraz doświadczonych programistów!",
        itemsFirstPackage: [
            {
                title: "Wskazówki od czego zacząć naukę programowania",
                description: "Podpowiadam czego warto się uczyć oraz co zrobić żeby nauka była przyjemniejsza i efektywniejsza.",
                iconColor: "icon-color-1",
                icon: "ti-light-bulb",
            },
            {
                title: "Wskazówki jak znaleźć pierwszą pracę w IT",
                description: "Wiele osób skorzystało z moich rad i znalazło pierwszą pracę na stanowisku programisty.",
                iconColor: "icon-color-2",
                icon: "ti-briefcase",
            },
            {
                title: "Zadania rekrutacyjne do rozwiązania",
                description: "Zadania teoretyczne oraz praktyczne o różnej skali trudności.",
                iconColor: "icon-color-3",
                icon: "ti-notepad",
            },
        ],
        itemsSecondPackage: [
            {
                title: "Pisane w ciekawy i zrozumiały sposób",
                description: "Wszystkie informacje staram się przedstawiać w jasny i prosty sposób z dużą ilością grafik.",
                iconColor: "icon-color-4",
                icon: "ti-target",
            },
            {
                title: "Źródła i materiały do nauki",
                description: "W wiadomościach umieszczam filmy, książki, artykuły, tutoriale warte uwagi i godne polecenia.",
                iconColor: "icon-color-5",
                icon: "ti-bookmark-alt",
            },
            {
                title: "Konkursy, promocje i specjalne rabaty",
                description: "Regularna lista z promocjami. Dodatkowo Czytelnicy PREMIUM otrzymują specjalne DevLog rabaty.",
                iconColor: "icon-color-6",
                icon: "ti-gift",
            }
        ]
    },
    premium: {
        title: "Jest też wersja PREMIUM 😉",
        description: "Wersja PREMIUM dostarczy Tobie jeszcze więcej wartości i wiedzy, a mi pozwoli pisać częściej i lepiej! Co otrzymasz?",
    },
    price: {
        packages: [
            {
                price: "0 zł / rok",
                license: "FREE",
                extra: "~ 24 wydania / rok",
                isFree: true,
                order: "CZYTAM!",
                features: [
                    "Wiadomości wypchane po brzegi wiedzą!",
                    "Wydania CO DRUGI piątek",
                    "Zadania rekrutacyjne",
                    "3 oferty pracy dla Juniora",
                    "Promocje i okazje",
                    "3 archiwalne wiadomości",
                    "Ciekawe i wartościowe linki, grafiki i materiały",
                    "Wydania na Twojej skrzynce email",
                ]
            },
            {
                price: " 190zł / rok",
                license: "FREE + PREMIUM (faktura VAT)",
                extra: "~ 48 wydań / rok",
                isFree: false,
                order: "BIORĘ!",
                features: [
                    "2x więcej treści i wiedzy",
                    "2x więcej wydań (w KAŻDY piątek)",
                    "Rozwiązania do zadań rekrutacyjnych",
                    "2x więcej ofert pracy dla Juniora",
                    "Kody rabatowe i zniżki tylko dla DevLog",
                    "Archiwum ze wszystkimi wiadomościami",
                    "Dostęp do BAZY WIEDZY",
                    "Wydania w wersji audio do odsłuchu",
                ]
            }
        ]
    },
    testimonial: {
        title: "Kim jestem?",
        description: "Programistą z 7-letnim doświadczeniem. Z sukcesami prowadzę bloga, kanał na YouTube oraz czuwam nad wspaniałą społecznością IT na Discordzie! Dzięki zdobytej wiedzy i doświadczeniu, ",
        boldDescription: "pomogłem już wielu osobom.",
        socials: [
            {
                alt: "YT",
                link: "https://www.youtube.com/c/ZaprogramujZycie?sub_confirmation=1",
                img: "img/youtube-logo.png"
            },
            {
                alt: "Discord",
                link: "https://discord.gg/cF7jDGeRAR",
                img: "img/discord-logo.png"
            },
            {
                alt: "Blog",
                link: "https://zaprogramujzycie.pl",
                img: "img/blog-logo.png"
            }
        ],
        comments: [
            {
                name: "Maximanek",
                company: "Discord",
                image: "img/discord-logo.png",
                comment: "Coś tak czułem że dołączenie do tego serwera będzie doświadczeniem które pchnie moje życie do przodu. [...] dziś zostałem zatrudniony jako Junior Laravel Dev."
            },
            {
                name: "Kamilek",
                company: "Discord",
                image: "img/discord-logo.png",
                comment: "Niedawno dostałem prace w laravelu m. in. dzięki temu discordowi i kanale Mateusza."
            },
            {
                name: "Ale Sto",
                company: "YouTube",
                image: "img/youtube-logo.png",
                comment: "Super filmik! Możesz śmiało konkurować z Zelentem na proste i szybko wpadające w pamięć tłumaczenie każdego zagadnienia;)"
            },
            {
                name: "Mateusz Kokot",
                company: "YouTube",
                image: "img/youtube-logo.png",
                comment: "Świetna seria. Tłumaczona bardzo zrozumiale i bez niepotrzebnych dygresji. Przechodzimy przez materiał konkretną ścieżką, na której się nie gubię. Czuć dydaktyczny sznyt :)"
            },
            {
                name: "Paweł",
                company: "Blog",
                image: "img/blog-logo.png",
                comment: "To mój pierwszy przeczytany wpis na tym blogu. Naprawdę super robota. Z przyjemnością przeczytam kolejne 🙂."
            },
            {
                name: "Kamil",
                company: "Blog",
                image: "img/blog-logo.png",
                comment: "Bardzo rzetelny artykuł, jakich brakuje."
            }
        ]
    },
    screenshots: {
        title: "Jakie tematy już się pojawiły?",
        description: "Poniżej kilka przykładowych wiadomości, które wysłałem Czytelnikom"
    },
    subscribe: {
        title: "Bądź o krok przed innymi, nie czekaj!",
        description: "Jeśli zapiszesz się już teraz, wyślę do Ciebie jedną z wcześniejszych wiadomości i przekonasz się, że było warto!"
    },
    footer: {
        title: "Polityka Prywatności",
        copyrights: "devlog.it © 2021 |",
        socials: [
            {
                title: "YouTube",
                link: "https://www.youtube.com/c/ZaprogramujZycie?sub_confirmation=1",
                icon: "ti-youtube"
            },
            {
                title: "Facebook",
                link: "https://www.facebook.com/zaprogramujzycie",
                icon: "ti-facebook"
            },
            {
                title: "Instagram",
                link: "https://www.instagram.com/zaprogramujzycie.pl",
                icon: "ti-instagram"
            },
            {
                title: "LinkedIn",
                link: "https://www.linkedin.com/company/zaprogramuj-zycie",
                icon: "ti-linkedin"
            },
            {
                title: "GitHub",
                link: "https://github.com/zaprogramujzycie",
                icon: "ti-github"
            }
        ]
    },
    notFound: {
        title: "404",
        subtitle: "Opss... nie ma takiej strony",
        button: "Strona główna"
    },
    privacy: {
        breadcrumbMainPage: "Strona główna",
        breadcrumb: "Polityka prywatności",
        button: "Strona główna",
        title: "Polityka prywatności",
        description: "Cześć! Super, że tutaj zajrzałeś, bo oznacza to, że dbasz o swoją prywatność",
    }
};
