import React from "react";
import _data from "../../data";
import Mailchimp from "../Mailchimp";

class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribe: {}
        };
    }

    componentDidMount() {
        this.setState({
            subscribe: _data.subscribe
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="background-img pt-4 text-center text-white">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h2 className="text-white">
                                    {this.state.subscribe.title}
                                </h2>
                                <p className="lead text-white">
                                    {this.state.subscribe.description}
                                </p>
                            </div>
                        </div>
                        <div className="clock countdown-wrap my-3"></div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                <Mailchimp/>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Subscribe;
