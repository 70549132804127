import React from "react";
import _data from "../../data";

export default class Screenshots extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenshots: {}
        };
    }

    componentDidMount() {
        this.setState({
            screenshots: _data.screenshots
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="screenshots" className="screenshots-section ptb-50 gray-about-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                <div className="section-heading text-center">
                                    <h2>
                                        {this.state.screenshots.title}
                                    </h2>
                                    <p className="lead">
                                        {this.state.screenshots.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="screen-slider-content mt-5">
                            <div className="screenshot-frame"></div>
                            <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                                <img src="img/screen-01.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-01-1.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-02.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-02-1.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-03.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-03-1.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-04.png" className="img-fluid" alt="screenshots"/>
                                <img src="img/screen-04-1.png" className="img-fluid" alt="screenshots"/>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
