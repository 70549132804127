import React from "react";
import _data from "../../data";
import Mailchimp from "../Mailchimp";

class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: {}
        };
    }

    componentDidMount() {
        this.setState({
            counter: _data.counter
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="counter" className="download-section pt-5 text-center background-img text-white">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h2 className="text-white">
                                    {this.state.counter.title}
                                </h2>
                                <p className="lead">
                                    {this.state.counter.description} <u>{this.state.counter.descriptionUnderline}</u> {this.state.counter.descriptionIcon}
                                </p>
                            </div>
                        </div>
                        <div className="clock countdown-wrap my-3"></div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                <Mailchimp/>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Counter;
