import React from "react";
import _data from "../../data";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Swal from 'sweetalert2';

const url = "https://zaprogramujzycie.us10.list-manage.com/subscribe/post?u=341d9ba476cf7941d25881c7c&amp;id=6bee986b7e";

class Mailchimp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mailchimp: {},
            email: "",
            textBtn: "",
            errorClass: ""
        };
    }

    componentDidMount() {
        this.setState({
            mailchimp: _data.mailchimp,
            textBtn: _data.mailchimp.button
        });
    }

    openModal = (status, message) => {
        const trans = this.state.mailchimp;
        if (status === "success" || (status === "error" && message.includes("jest już zapisany do listy"))) {
            this.getModal(
                trans.titleModalSuccess,
                "img/good-job.gif",
                "Good Job!",
                trans.descriptionModalSuccess,
                trans.buttonModalSuccess
            );
        } else if (status === "error") {
            this.getModal(
                trans.titleModalError,
                "img/sad.gif",
                "Something went wrong...",
                trans.descriptionModalError,
                trans.buttonModalError
            );
        }
    }

    getModal = (title, gif, alt, description, button) => {
        return Swal.fire({
            title: title,
            html: '<div>' +
                    '<div class="row">' +
                        '<div class="col-12 col-sm-12 col-md-12 col-lg-12">' +
                            '<img src="' + gif + '" alt="' + alt + '" class="img-fluid"/>' +
                        '</div>' +
                    '</div>' +
                    '<div class="row pt-15">' +
                        '<div class="col-12 col-sm-12 col-md-12 col-lg-12">' +
                            '<p class="lead">' + description + '</p>' +
                        '</div>' +
                    '</div>' +
                '</div>',
            confirmButtonText: button,
            confirmButtonColor: "#6730e3",
            didClose() {
                window.location.reload();
            }
        });
    }

    getButtonText = (status) => {
        if (status === "sending") {
            return this.state.mailchimp.buttonSend;
        } else if (status === "error") {
            return this.state.mailchimp.button;
        } else if (status === "success") {
            return this.state.mailchimp.buttonSuccess;
        } else {
            return this.state.textBtn;
        }
    }

    isButtonDisabled = (status) => {
        return status === "sending" || status === "success";
    }

    handleFormValueChange = (event) => {
        this.setState({
            email: event.target.value,
            errorClass: ""
        });
    }

    handleSubmit = (subscribe) => {
        const mailFormat = /^[^\s@]+@[^\s@]+$/;
        let email = this.state.email;
        if (email.length === 0 || !(email.match(mailFormat))) {
            this.setState({
                errorClass: "error"
            });
        } else {
            subscribe({
                EMAIL: email
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <MailchimpSubscribe
                        url={url}
                        render={({subscribe, status, message}) => (
                            <div>
                                {this.openModal(status, message)}
                                <div className="subscribe-form">
                                    <div className="row align-items-center">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={"form-control input email " + this.state.errorClass}
                                                name="email"
                                                placeholder={this.state.mailchimp.input}
                                                value={this.state.email}
                                                onChange={e => this.handleFormValueChange(e)}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="button btn solid-btn"
                                                    type="button"
                                                    disabled={this.isButtonDisabled(status)}
                                                    onClick={() => this.handleSubmit(subscribe)}
                                                >
                                                    {this.getButtonText(status)}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                    <p className="newsletter-note">
                        {this.state.mailchimp.rules}
                    </p>
                </div>
            </React.Fragment>
        );
    }
}

export default Mailchimp;