import React from "react";
import {connect} from "react-redux";
import _data from "../../data";

class HeroSectionFaq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            privacy: {}
        };
    }

    componentDidMount() {
        this.setState({
            privacy: _data.privacy
        });
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className="hero-section ptb-50 background-img"
                    style={{
                        backgroundImage: "url('img/hero-bg-1.jpg')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-9 col-lg-7">
                                <div className="text-white text-center">
                                    <img
                                        src="img/logo.png"
                                        alt="app"
                                        width={225}
                                        className="img-fluid"
                                    />
                                    <div className="custom-breadcrumb">
                                        <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                            <li className="list-inline-item breadcrumb-item">
                                                <a href="/">{this.state.privacy.breadcrumbMainPage}</a>
                                            </li>
                                            <li className="list-inline-item breadcrumb-item active">
                                                {this.state.privacy.breadcrumb}
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state,
}))(HeroSectionFaq);
