import React from "react";
import _data from "../../data";

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: {}
        };
    }

    componentDidMount() {
        this.setState({
            notFound: _data.notFound
        });
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className="hero-section ptb-100 background-img full-screen"
                    style={{
                        backgroundImage: "url('img/background-main.jpg')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-9 col-lg-7">
                                <div className="error-content text-center text-white">
                                    <div className="notfound-404">
                                        <h1 className="text-white">{this.state.notFound.title}</h1>
                                    </div>
                                    <h3 className="text-white">{this.state.notFound.subtitle}</h3>
                                    <a className="btn app-store-btn" href="/">
                                        {this.state.notFound.button}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default NotFound;
