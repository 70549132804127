import React, {Component} from "react";
import _data from "../../data";

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial: {}
        };
    }

    componentDidMount() {
        this.setState({
            testimonial: _data.testimonial
        });
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className={"testimonial-section ptb-50"}>
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                <div className="section-heading mb-5">
                                    <h2>
                                        {this.state.testimonial.title}
                                    </h2>
                                    <p className="lead">
                                        {this.state.testimonial.description}
                                        <b><u>{this.state.testimonial.boldDescription}</u></b>
                                    </p>

                                    <div className="client-section-wrap d-flex flex-row align-items-center">
                                        <ul className="list-inline">
                                            {(this.state.testimonial.socials || []).map(social => {
                                                return (
                                                    <li key={social.alt} className="list-inline-item">
                                                        <a href={social.link}
                                                           target={"_blank"}
                                                           rel="noreferrer">
                                                            <img
                                                                src={social.img}
                                                                width="40"
                                                                alt={social.alt}
                                                                className="img-fluid"
                                                            />
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                <div className="owl-carousel owl-theme client-testimonial arrow-indicator">
                                    {(this.state.testimonial.comments || []).map(
                                        (comment, _index) => {
                                            return (
                                                <div className="item" key={comment.name}>
                                                    <div className="testimonial-quote-wrap">
                                                        <div className="media author-info mb-3">
                                                            <div className="author-img mr-3">
                                                                <img
                                                                    src={comment.image}
                                                                    alt="client"
                                                                    className="img-fluid rounded-circle"
                                                                />
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="mb-0">{comment.name}</h5>
                                                                <span>{comment.company}</span>
                                                            </div>
                                                        </div>
                                                        <div className="client-say">
                                                            <p>
                                                                <img
                                                                    src="img/quote.png"
                                                                    alt="quote"
                                                                    className="img-fluid"
                                                                />
                                                                {comment.comment}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Testimonial;
