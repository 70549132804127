import React, {Component} from "react";

import Privacy from "../components/Privacy";
import HeroSection from "../components/Hero/HeroSectionFaq";

class PrivacyTheme extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <HeroSection />
                    <Privacy/>
                </div>
            </React.Fragment>
        );
    }
}

export default PrivacyTheme;
