import React, {Component} from "react";

import Hero from "../components/Hero";
import Promo from "../components/Promo";
import Counter from "../components/Counter";
import Testimonial from "../components/Testimonial";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import Screenshots from "../components/Screenshots";
import Pricing from "../components/Pricing";
import Feature from "../components/Feature";
import Premium from "../components/Premium";

class Page extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <Hero/>
                    <Promo/>
                    <Feature/>
                    <Counter/>
                    <Premium/>
                    <Pricing/>
                    <Screenshots/>
                    <Testimonial/>
                </div>
                <Subscribe/>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default Page;
