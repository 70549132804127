import React from "react";
import _data from "../../data";
import Swal from 'sweetalert2';

export default class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            price: {}
        };
    }

    componentDidMount() {
        this.setState({
            price: _data.price
        });
    }

    handleButtonClick(isFree) {
        if (isFree) {
            const subscribe = document.querySelector('#counter');
            subscribe.scrollIntoView({
                behavior: 'smooth'
            }, 5000)
        } else {
            Swal.fire({
                html: '<div>' +
                        '<div class="row">' +
                            '<div class="col-12 col-sm-12 col-md-12 col-lg-12">' +
                                '<img src="/img/thanks.jpg" alt="Pracuję nad tym" class="img-fluid"/>' +
                            '</div>' +
                        '</div>' +
                        '<div class="row pt-15">' +
                            '<div class="col-12 col-sm-12 col-md-12 col-lg-12">' +
                                '<p class="lead">Pracuję właśnie nad możliwością płatności online ale chwilę to potrwa 😞</p>' +
                                '<p class="lead"><b>Wszystkie szczegóły opisałem <a href="https://mailchi.mp/920ac2700340/devlog-wydanie-7977205" target="_blank">TUTAJ</a></b></p>' +
                                '<p class="lead">Napisz proszę wiadomość na <a href="mailto: news@devlog.it">news@devlog.it</a>. Wyślę do Ciebie dane do przelewu, wystawię fakturę VAT i nadam uprawnienia Czytelnika PREMIUM 🙂</p>' +
                            '</div>' +
                        '</div>' +
                    '</div>',
                confirmButtonText: "Napisz do mnie!",
                confirmButtonColor: "#6730e3"
            });
        }
    }

    isPopularPrice(isFree) {
        if (!isFree) {
            return "popular-price";
        }
        return "";
    }

    render() {
        return (
            <React.Fragment>
                <div className="overflow-hidden">
                    <section
                        id="pricing"
                        className={"package-section pb-50"}
                    >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-12 col-md-10 col-lg-10">
                                    <div className="section-heading text-center mb-5">
                                        <h2>
                                            {this.state.price.title}
                                        </h2>
                                        <p className="lead">
                                            {this.state.price.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                {(this.state.price.packages || []).map((_package, index) => {
                                    return (
                                        <div className="col-12 col-sm-12 col-lg-4 col-md-4" key={index}>
                                            <div
                                                className={"card text-center single-pricing-pack " + this.isPopularPrice(_package.isFree)}
                                            >
                                                <div className="pt-4">
                                                    <h5>{_package.license}</h5>
                                                </div>
                                                <div className="card-header py-4 border-0 pricing-header">
                                                    <div className="h1 text-center mb-0">
                                                        {/*{!_package.isFree ?*/}
                                                        {/*    <span className={"promotion"}>*/}
                                                        {/*        <del>190 zł</del>*/}
                                                        {/*    </span> : <></>*/}
                                                        {/*}*/}
                                                        <span className="price font-weight-bolder">
                                                          {_package.price}
                                                        </span>
                                                    </div>
                                                    <p className="text-center mb-0">
                                                        {_package.extra}
                                                    </p>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled text-sm mb-4 pricing-feature-list">
                                                        {(_package.features || []).map(feature => {
                                                            return <li key={feature}>{feature}</li>;
                                                        })}
                                                    </ul>
                                                    <button
                                                        onClick={() => this.handleButtonClick(_package.isFree)}
                                                        className={"btn mb-3 outline-btn"}
                                                        rel="noreferrer"
                                                    >
                                                        <b>{_package.order}</b>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}
