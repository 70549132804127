import React from "react";
import _data from "../../data";
import Mailchimp from "../Mailchimp";

class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hero: {}
        };
    }

    componentDidMount() {
        this.setState({
            hero: _data.hero
        });
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className="hero-section background-img"
                    style={{
                        backgroundImage: `url(${this.state.hero.bgImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-between py-5">
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                <div className="hero-content-left text-white text-center">
                                    <img
                                        src="img/logo.png"
                                        alt="app"
                                        width={150}
                                        className="img-fluid"
                                    />
                                    <h2 className="text-white">
                                        {this.state.hero.title}
                                    </h2>
                                    <p className="lead">
                                        {this.state.hero.description}
                                    </p>
                                    <Mailchimp/>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                                <div className="hero-animation-img">
                                    <a href="https://mailchi.mp/091a3673e00b/devlog-wydanie-1" target="_blank" rel="noreferrer">
                                        <img
                                            src="img/app-product_mail_1.png"
                                            alt="app"
                                            className="img-fluid"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Hero;
